var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',_vm._g({attrs:{"mutation":_vm.deleteMutation},on:{"done":function($event){_vm.visible = false;
    _vm.deleted = true;
    _vm.notifyForDelete();}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var mutate = ref.mutate;
    var loading = ref.loading;
return [_c('v-snackbar',{attrs:{"color":"red"},model:{value:(_vm.deleted),callback:function ($$v) {_vm.deleted=$$v},expression:"deleted"}},[_vm._v(" Цикл был удален ")]),_c('v-dialog',{attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"red","text":"","disabled":!_vm.cycleId}},on),[_vm._v(" Удалить ")])]}}],null,true),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_vm._v("Удалить цикл?")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":"","loading":loading},on:{"click":function($event){return mutate({
              variables: { id: _vm.cycleId },
            })}}},[_vm._v(" Удалить ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.visible = false}}},[_vm._v("отмена")])],1)],1)],1)]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }