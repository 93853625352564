var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EventsTable',{attrs:{"items":_vm.items,"parent-cycle":_vm.cycleId},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","outlined":"","small":"","to":{ name: _vm.Routes.event, query: { parent: _vm.cycleId } }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-calendar-plus")]),_vm._v(" добавить ")],1)]},proxy:true},{key:"item._actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"d-flex flex-row",attrs:{"justify":"end"}},[_c('v-dialog',{attrs:{"value":_vm.deleteId === item.id,"max-width":"400","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red"},on:{"click":function($event){_vm.deleteId = item.id}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Исключить мероприятие")]),_c('v-card-text',[(_vm.error !== null)?_c('v-alert',{attrs:{"color":"red","text":"","dense":""}},[_vm._v(" Произошла ошибка "),_c('details',[_vm._v(_vm._s(_vm.error))])]):_vm._e(),_vm._v(" Мероприятие \""+_vm._s(item.public_name)+"\" будет исключено из цикла и деактиваровано ")],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":"","loading":_vm.loading},on:{"click":function($event){return _vm.detach(item.id)}}},[_vm._v(" Исключить и деактивировать ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteId = null}}},[_vm._v("отмена")])],1)],1)],1),_c('v-btn',{attrs:{"icon":"","to":{
          name: _vm.Routes.eventID,
          params: { id: item.id },
          query: { parent: _vm.cycleId },
        }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }