








































import { Component, Prop, Vue } from "vue-property-decorator";
import { deleteEventCycle } from "@/graphql/queries/Events.graphql";

@Component
export default class CycleDelete extends Vue {
  @Prop({ type: String, default: null }) cycleId!: string;

  protected deleted = false;
  protected visible = false;
  protected readonly deleteMutation = deleteEventCycle;

  protected notifyForDelete(): void {
    setTimeout(() => this.$emit("deleted"), 1000);
  }
}
