































































































































































































































































































































































































import { Component, Watch } from "vue-property-decorator";

import {
  createEventCycle,
  cycleEventByIdFromTable,
  updateEventCycle,
  participantsFromCycleEventByIdFromTable,
} from "@/graphql/queries/Events.graphql";
import {
  AdditionalArchiveBlock,
  AdditionalBlock,
  ConnectSyncPartners,
  CreateEventCycleInput,
  CreateEventCycleMutation,
  CreateEventCycleMutationVariables,
  CycleEventByIdQuery,
  CycleEventByIdQueryVariables,
  DoctorSpecialty,
  PaymentTypeEnum,
  UpdateEventCycleInput,
  UpdateEventCycleMutation,
  UpdateEventCycleMutationVariables,
  User,
  Event as AppEvent,
  QueryAllDoctorsSpecialtiesOrderByColumn,
  SortOrder,
} from "@/generated/graphql";
import { AllDoctorSpecialities } from "@/graphql/queries/DoctorsSpecialities.graphql";
import MutationTransformer from "@/core/Transformers/GraphqlMutationFieldsTransformer";

import {
  categoryEventCycle,
  eventForms,
  eventStatuses,
  paymentTypes,
} from "@/core/static/dict";

import { Routes } from "@/types/core";
import omitDeep from "omit-deep-lodash";
import { mixins } from "vue-class-component";
import { BaseView } from "@/core/UI/Views/BaseView";
import userRolesMixin from "@/mixins/userRoles";

import DateInput from "@widgets/commons/Inputs/DateInput.vue";
import CityAutocomplete from "@/components/widgets/commons/Inputs/CityAutocomplete.vue";
import Statistics from "@/components/widgets/events/Statistics.vue";
import UsersTable from "@/components/parts/tables/UsersTable.vue";
import CycleEvents from "@widgets/cycles/CycleEvents.vue";
import CycleArchive from "@/components/widgets/cycles/CycleArchive.vue";
import CycleDelete from "@/components/widgets/cycles/CycleDelete.vue";
import CycleDeactivate from "@/components/widgets/cycles/CycleDeactivate.vue";
import EventPersons from "@/components/widgets/events/EventPersons.vue";
import Map from "@widgets/commons/Map.vue";
import FileInput from "@widgets/commons/Inputs/FileInput.vue";
import ImageInput from "@widgets/commons/Inputs/ImageInput.vue";
import AdditionalBlocks from "@widgets/events/AdditionalBlocks.vue";
import PartnersList from "@/components/widgets/events/PartnersList.vue";
import { Maybe } from "graphql/jsutils/Maybe";
import { cloneDeep } from "lodash";
import AdditionalArchiveBlocks from "@widgets/events/AdditionalArchiveBlocks.vue";
import EventProgram from "@widgets/events/EventProgram.vue";

type Participant = NonNullable<
  NonNullable<
    NonNullable<CycleEventByIdQuery["cycleEvent"]>["events"]
  >[number]["participants"]
>;

@Component({
  name: "cycleEvent",
  components: {
    AdditionalArchiveBlocks,
    EventPersons,
    CycleEvents,
    CycleArchive,
    CycleDeactivate,
    CycleDelete,

    EventProgram,

    UsersTable,
    PartnersList,
    FileInput,
    ImageInput,
    AdditionalBlocks,
    DateInput,
    CityAutocomplete,
    Map,
    Statistics,
  },
  apollo: {
    cycleEvent: {
      query: cycleEventByIdFromTable,
      fetchPolicy: "cache-and-network",
      variables(): CycleEventByIdQueryVariables {
        return { id: this.$route.params.id };
      },
      update(this: CycleEvent, result: CycleEventByIdQuery) {
        if (this.$route.hash) this.scrollToSection();
        return result.cycleEvent;
      },
      skip(this: CycleEvent) {
        return this.creationMode;
      },
    },
    cycleEventParticipants: {
      query: participantsFromCycleEventByIdFromTable,
      variables() {
        return { id: this.$route.params.id };
      },
      update(data) {
        return data.cycleEvent.events;
      },
      skip(this: CycleEvent) {
        return this.creationMode;
      },
    },
    allDoctorsSpecialties: {
      query: AllDoctorSpecialities,
      variables() {
        return {
          orderBy: [
            {
              column: QueryAllDoctorsSpecialtiesOrderByColumn.Name,
              order: SortOrder.Asc,
            },
          ],
        };
      },
    },
  },
})
export default class CycleEvent extends mixins(userRolesMixin, BaseView) {
  private readonly dataTransformer = new MutationTransformer();
  protected readonly updateMutation = updateEventCycle;
  protected isArchive: boolean = false;
  protected Routes = Routes;
  private readonly eventForms = eventForms;
  private readonly paymentTypes = paymentTypes;
  private readonly eventStatuses = eventStatuses;
  private readonly categoryEventCycle = categoryEventCycle;
  protected readonly PaymentTypeEnum = PaymentTypeEnum;
  protected file: File | string = "";

  /**
   * Данные аполло
   * @protected
   */
  protected readonly cycleEvent: CycleEventByIdQuery["cycleEvent"] = null;

  cycleEventParticipants: Maybe<Array<AppEvent>> = null;
  /**
   * Данные компонента
   * @protected
   */
  protected stateCycleEvent: Omit<
    Partial<NonNullable<CycleEventByIdQuery["cycleEvent"]>>,
    "logotype" | "main_image" | "place_image" | "activities"
  > & {
    logotype?: Maybe<string | File>;
    main_image?: Maybe<string | File>;
    place_image?: Maybe<string | File>;
    activities?: File | string | null;
  } = {};

  @Watch("cycleEvent", { deep: true, immediate: true })
  handlerCycleEvent(cycleEvent: CycleEventByIdQuery["cycleEvent"]): void {
    if (cycleEvent) {
      this.stateCycleEvent = cloneDeep(cycleEvent);
    }
  }

  protected allDoctorsSpecialties: DoctorSpecialty[] = [];

  loading = 0;
  error = "";

  protected isUpdateProcess: boolean = false;
  protected isUpdated: boolean = false;
  protected isUpdateError: boolean = false;
  protected isUpdatedText: string = "Данные обновлены";
  protected isUpdatedErrorText: string = "Данные обновлены";

  private deleteAdditionalList: string[] = [];
  private deleteArchiveAdditionalList: string[] = [];

  public created(): void {
    if (!this.VIEW_EVENT_CYCLE) this.$router.push({ name: Routes.noRights });
  }
  public back(): void {
    const link = this.cycleEvent?.is_archive
      ? Routes["admin/archive"]
      : Routes["cycleEvents"];
    this.$router.push({ name: link });
  }
  /**
   * Собираем массив доп блоков на удаление
   */

  private setInDeleteArray(id: string): void {
    this.stateCycleEvent.is_archive
      ? this.deleteArchiveAdditionalList.push(id)
      : this.deleteAdditionalList.push(id);
  }

  /**
   * Обновление при возрате на вкладку для поддержания истинности данных
   */
  public async mounted(): Promise<void> {
    let mustUpdate: boolean = false;
    document.addEventListener("visibilitychange", async () => {
      if (document.visibilityState == "visible") {
        if (mustUpdate) await this.$apollo.queries.cycleEvent.refresh();
      } else {
        mustUpdate = true;
      }
    });
  }

  protected scrollToSection(): void {
    const el = document.getElementById(this.$route.hash.replace("#", ""));
    if (el) {
      setTimeout(() =>
        el.scrollIntoView({ behavior: "smooth", block: "center" })
      );
    }
  }

  protected get participants(): Maybe<Participant> {
    if (this.cycleEventParticipants?.length) {
      return this.cycleEventParticipants.reduce((users: Participant, event) => {
        event.participants?.forEach((participant: User) => {
          const isUserAlreadyExist = !!users.find(
            (u) => u.id === participant.id
          );
          if (!isUserAlreadyExist) users.push(participant);
        });
        return users;
      }, []);
    }
    return [];
  }

  protected get creationMode(): boolean {
    return !this.$route.params.id;
  }

  protected get creationInput(): CreateEventCycleInput | null {
    if (!this.creationMode || !this._commonInput) {
      return null;
    }

    // FIX: Временный фикс
    // TODO: Добавить обязательность поля date_from в схеме на сервере
    return this._commonInput as unknown as CreateEventCycleInput;
  }

  protected get updateInput(): UpdateEventCycleInput | null {
    if (this.creationMode || !this._commonInput) {
      return null;
    }

    if ("id" in this._commonInput) {
      if (typeof this._commonInput.id === "string") {
        return { ...this._commonInput, id: this._commonInput.id };
      }
    }

    if (typeof this.stateCycleEvent.id === "string") {
      return { ...this._commonInput, id: this.stateCycleEvent.id };
    }

    return null;
  }

  /**
   * Геттер с общими полями инпута
   * Тут описаны общие поля и для формы создания и для формы обновления
   * @protected
   */
  protected get _commonInput():
    | Partial<UpdateEventCycleInput | CreateEventCycleInput>
    | undefined {
    const stateCycleEvent = this.stateCycleEvent;

    if (!stateCycleEvent) {
      return undefined;
    }

    let input: Partial<UpdateEventCycleInput | CreateEventCycleInput> = {
      specialties: {},
      public_name: stateCycleEvent.public_name,
      private_name: stateCycleEvent.private_name,
      price: stateCycleEvent.price,
      status: stateCycleEvent.status,
      payment_type: stateCycleEvent.payment_type,
      event_form: stateCycleEvent.event_form,
      is_registration_disabled: stateCycleEvent.is_registration_disabled,
      description: stateCycleEvent.description,
      date_from: stateCycleEvent.date_from,
      date_to: stateCycleEvent.date_to,
      geoposition: stateCycleEvent.geoposition,
      event_category: stateCycleEvent.event_category,
      hide_anchorpersons: stateCycleEvent.hide_anchorpersons,
      hide_managers: stateCycleEvent.hide_managers,
      hide_lecturers: stateCycleEvent.hide_lecturers,
      hide_producers: stateCycleEvent.hide_producers,
    };

    if (stateCycleEvent.additionalBlocks) {
      const _clearFiles = (block: AdditionalBlock): AdditionalBlock => {
        const _block = { ...block };
        if (typeof _block.file === "string") delete _block.file;
        if (typeof _block.image === "string") delete _block.image;
        return _block;
      };
      const additional = stateCycleEvent.additionalBlocks;

      const blocksForCreation = additional
        ?.filter((block) => !block.id && block.type)
        .map(_clearFiles);

      const blocksForUpdate = additional
        ?.filter((block) => block.id)
        .map(_clearFiles);

      input.additionalBlocks = {
        create: blocksForCreation,
        update: blocksForUpdate,
        delete: this.deleteAdditionalList || [],
      };
    }

    if (stateCycleEvent.additionalArchiveBlocks && stateCycleEvent.is_archive) {
      const _clearFiles = (
        block: AdditionalArchiveBlock
      ): AdditionalArchiveBlock => {
        const _block = { ...block };
        if (!block.files?.every((file) => file instanceof File))
          delete _block.files;
        if (!block.images?.every((image) => image instanceof File))
          delete _block.images;
        return _block;
      };
      const additional = stateCycleEvent.additionalArchiveBlocks;

      const blocksForCreation = additional
        ?.filter((block) => !block.id && block.type)
        .map(_clearFiles);

      const blocksForUpdate = additional
        ?.filter((block) => block.id)
        .map(_clearFiles);

      // TODO: Разделить commonInput на update/create input
      //@ts-ignore
      input.additionalArchiveBlocks = {
        create: blocksForCreation,
        update: blocksForUpdate,
        delete: this.deleteArchiveAdditionalList || [],
      };
    }

    if (stateCycleEvent.partners) {
      input.partners = {
        sync: stateCycleEvent.partners.reduce(
          (sync: ConnectSyncPartners[], partner) => {
            if (partner.pivot?.partner_status_id) {
              sync.push({
                id: partner.id,
                partner_status_id: partner.pivot.partner_status_id,
              });
            }
            return sync;
          },
          []
        ),
      };
    }

    if (stateCycleEvent.specialties) {
      input.specialties = this.dataTransformer.transformArrayToSync(
        stateCycleEvent.specialties
      );
    }
    if (!stateCycleEvent.producers?.length) input.producers = { sync: [] };
    if (!stateCycleEvent.managers?.length) input.managers = { sync: [] };
    if (!stateCycleEvent.anchorpersons?.length)
      input.anchorpersons = { sync: [] };
    if (!stateCycleEvent.lecturers?.length) input.lecturers = { sync: [] };

    if (stateCycleEvent.producers && stateCycleEvent.producers?.length > 0)
      input.producers = {
        sync: stateCycleEvent.producers.map((user) => ({
          id: user.id,
          person_status_id: user.producer_status?.person_status_id,
        })),
      };

    if (stateCycleEvent.lecturers && stateCycleEvent.lecturers?.length > 0)
      input.lecturers = {
        sync: stateCycleEvent.lecturers.map((user) => ({
          id: user.id,
          person_status_id: user.lecturer_status?.person_status_id,
        })),
      };

    if (stateCycleEvent.managers && stateCycleEvent.managers?.length > 0)
      input.managers = {
        sync: stateCycleEvent.managers.map((user) => ({
          id: user.id,
          person_status_id: user.manager_status?.person_status_id,
        })),
      };

    if (
      stateCycleEvent.anchorpersons &&
      stateCycleEvent.anchorpersons?.length > 0
    )
      input.anchorpersons = {
        sync: stateCycleEvent.anchorpersons.map((user) => ({
          id: user.id,
          person_status_id: user.anchor_status?.person_status_id,
        })),
      };

    const city = stateCycleEvent.city;
    if (city && typeof city === "object") {
      input.city_id = omitDeep(city, "id");
    }

    if (stateCycleEvent.logotype instanceof File) {
      input.logotype = stateCycleEvent.logotype;
    }
    if (
      stateCycleEvent.logotype instanceof File ||
      stateCycleEvent.logotype === null
    )
      input.logotype = stateCycleEvent.logotype;
    if (
      stateCycleEvent.main_image instanceof File ||
      stateCycleEvent.main_image === null
    )
      input.main_image = stateCycleEvent.main_image;
    if (
      stateCycleEvent.place_image instanceof File ||
      stateCycleEvent.place_image === null
    )
      input.place_image = stateCycleEvent.place_image;

    if (stateCycleEvent.specialties) {
      input.specialties = this.dataTransformer.transformArrayToSync(
        stateCycleEvent.specialties
      );
    }

    if (stateCycleEvent.events) {
      input.events = this.dataTransformer.transformArrayToSync(
        stateCycleEvent.events
      );
    }

    if (stateCycleEvent.event_category) {
      input.event_category = stateCycleEvent.event_category;
    }

    if (
      stateCycleEvent.activities instanceof File ||
      stateCycleEvent.activities === null
    ) {
      input.activities = stateCycleEvent.activities;
    }

    if (stateCycleEvent.status) {
      input.status = stateCycleEvent.status;
    }

    return input;
  }

  async send(isExit: boolean): Promise<void> {
    try {
      this.loading += 1;
      this.error = "";
      if (this.creationMode && this.creationInput) {
        const result = await this.$apollo.mutate<
          CreateEventCycleMutation,
          CreateEventCycleMutationVariables
        >({
          mutation: createEventCycle,
          variables: {
            input: this.creationInput,
          },
        });
        if (isExit) {
          this.back();
        } else {
          await this.$router.push(
            `/cycle-event/${result.data?.createEventCycle.id}`
          );
        }
      } else if (this.updateInput) {
        this.isUpdateProcess = true;
        await this.$apollo.mutate<
          UpdateEventCycleMutation,
          UpdateEventCycleMutationVariables
        >({
          mutation: updateEventCycle,
          variables: {
            input: this.updateInput,
          },
        });
        this.isUpdatedText = "Данные обновлены";
        this.isUpdateProcess = false;
        this.isUpdated = true;
        if (isExit) {
          this.back();
        }
      }
    } catch (error: unknown) {
      if (error instanceof Error) this.error = error.message;
      this.error = String(error);
      this.isUpdateError = true;
      this.isUpdatedErrorText = this.error;
      throw error;
    } finally {
      this.loading -= 1;
      this.isUpdateProcess = false;
    }
  }
}
