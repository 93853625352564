var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloMutation',_vm._g({attrs:{"mutation":_vm.updateMutation},on:{"done":function($event){_vm.visible = false;
    _vm.$emit('deactivated');}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var mutate = ref.mutate;
    var mutateLoading = ref.loading;
return [_c('v-dialog',{attrs:{"max-width":"400","persistent":mutateLoading},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":_vm.isActive ? 'red' : 'green',"text":"","loading":_vm.loading,"disabled":null === _vm.cycleId || _vm.loading}},on),[_vm._v(" "+_vm._s(_vm.isActive ? "Деактивировать цикл" : "Активировать цикл")+" ")])]}}],null,true),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.isActive ? "Деактивировать цикл?" : "Активировать цикл?"))]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":"","loading":mutateLoading},on:{"click":function($event){return mutate({
              variables: {
                input: { id: _vm.cycleId, is_active: !_vm.isActive },
              },
            })}}},[_vm._v(" "+_vm._s(_vm.isActive ? "Деактивировать" : "Активировать")+" ")]),_c('v-btn',{attrs:{"text":"","disabled":mutateLoading},on:{"click":function($event){_vm.visible = false}}},[_vm._v("отмена")])],1)],1)],1)]}}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }