


































































import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import {
  Event,
  UpdateEventCycleMutation,
  UpdateEventCycleMutationVariables,
  UpdateEventMutation,
  UpdateEventMutationVariables,
} from "@/generated/graphql";
import EventsTable from "@components/parts/tables/EventsTable.vue";
import EventsList from "@widgets/events/EventsList.vue";
import { Routes } from "@/types/core";
import {
  UpdateEvent,
  updateEventCycle,
} from "@/graphql/queries/Events.graphql";

@Component({
  components: {
    EventsTable,
    EventsList,
  },
})
export default class CycleEvents extends Vue {
  @Prop({ type: String, default: null }) cycleId!: string;
  @VModel({ type: Array, default: () => [] })
  items!: Event[];

  protected deleteId = null;

  protected readonly updateEvent = UpdateEvent;
  protected readonly updateEventCycle = updateEventCycle;
  protected readonly Routes = Routes;

  protected error = null;
  protected loading = false;
  protected async detach(id: Event["id"]): Promise<void> {
    try {
      this.loading = true;
      const newEventsSync = {
        sync: this.items.map((event: Event) => event.id),
      };
      newEventsSync.sync.splice(newEventsSync.sync.indexOf(id), 1);

      await this.$apollo.mutate<
        UpdateEventMutation,
        UpdateEventMutationVariables
      >({
        mutation: UpdateEvent,
        variables: {
          input: {
            id,
            is_active: false,
            hide_managers: false,
            hide_producers: false,
            hide_lecturers: false,
            hide_anchorpersons: false,
          },
        },
      });

      await this.$apollo.mutate<
        UpdateEventCycleMutation,
        UpdateEventCycleMutationVariables
      >({
        mutation: updateEventCycle,
        variables: {
          input: {
            id: this.cycleId,
            events: newEventsSync,
          },
        },
      });

      this.$emit("event-detach");

      this.deleteId = null;
    } catch (e) {
      //@ts-ignore
      this.error = e;
    } finally {
      this.loading = false;
    }
  }
}
