



















































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  UpdateEvent,
  updateEventCycle,
} from "@/graphql/queries/Events.graphql";

@Component
export default class CycleDeactivate extends Vue {
  @Prop({ type: String, default: null }) cycleId!: string | null;
  @Prop({ type: Boolean, default: false }) isActive!: string;
  @Prop({ type: Boolean, default: false }) loading!: string;

  protected visible = false;
  protected readonly updateMutation = updateEventCycle;
}
