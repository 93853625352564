





























































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Card from "@components/page/Card.vue";
import {
  UpdateEvent,
  updateEventCycle,
} from "@/graphql/queries/Events.graphql";

@Component({
  components: {
    Card,
  },
})
export default class CycleArchive extends Vue {
  @Prop({ type: String, default: null }) cycleId!: number;
  @Prop({ type: Boolean, default: null }) isArchive!: boolean;
  @Prop({ type: Boolean, default: false }) loading!: boolean;

  private readonly UpdateEvent = UpdateEvent;
  private readonly UpdateEventCycle = updateEventCycle;

  protected visible = false;

  protected atchivatedAllDone = false;
  protected archiveCycle = false;
  protected archiveError = false;
  protected archiveProgress = false;
  protected archived: string[] = [];
  protected archiveId: string[] = [];

  protected async packetArchive(ids: string[]): Promise<void> {
    try {
      this.archiveProgress = true;

      await Promise.all(
        ids.map(async (id) => {
          try {
            this.archiveId.push(id);
            await this.$apollo.mutate({
              mutation: UpdateEvent,
              variables: {
                input: { id, is_archive: !this.isArchive },
              },
            });
            this.archiveId.splice(this.archiveId.indexOf(id), 1);
            this.archived.push(id);
          } catch (e: unknown) {
            this.archiveError = true;
          }
        })
      );

      await this.doArchiveCycle();
    } catch (e: unknown) {
      this.archiveError = true;
    } finally {
      this.archiveId = [];
      this.archived = [];
      this.archiveProgress = false;
    }
  }

  protected async doArchiveCycle(): Promise<void> {
    try {
      this.archiveCycle = true;
      await this.$apollo.mutate({
        mutation: updateEventCycle,
        variables: {
          input: { id: this.cycleId, is_archive: !this.isArchive },
        },
      });
    } catch (e: unknown) {
      this.archiveError = true;
    } finally {
      this.visible = false;
      this.atchivatedAllDone = true;
      this.$emit("archived");
    }
  }
}
